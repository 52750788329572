import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate
import { CartContext } from '../contexts/CartContext';
import emailjs from '@emailjs/browser';

const Checkout = () => {
  const { cart, getTotalCartValue } = useContext(CartContext);
  const navigate = useNavigate(); // Updated to useNavigate
  const [address, setAddress] = useState({
    fullName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: '',
  });

  const [errors, setErrors] = useState({}); // State to track form validation errors
  const [showModal, setShowModal] = useState(false); // State to show the success modal

  const handleInputChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  // Form validation function
  const validateForm = () => {
    const newErrors = {};
    if (!address.fullName) newErrors.fullName = 'Full name is required';
    if (!address.email) newErrors.email = 'Email is required';
    if (!address.addressLine1) newErrors.addressLine1 = 'Address Line 1 is required';
    if (!address.city) newErrors.city = 'City is required';
    if (!address.postalCode) newErrors.postalCode = 'Postal code is required';
    if (!address.country) newErrors.country = 'Country is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handlePayment = async () => {
    // Validate the form before proceeding to payment
    if (!validateForm()) {
      alert('Please fill in all the required fields.');
      return;
    }

    const totalAmount = getTotalCartValue() * 100; // Amount in paise for Razorpay
    const options = {
      key: 'rzp_live_a6C9Dbz65ZL04V',
      amount: totalAmount,
      // amount: 1,
      currency: 'INR',
      name: 'Lit Luxe India',
      description: 'Order Payment',
      handler: async (response) => {
        // This function will be called after successful payment
        const orderDetails = {
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
          amount: getTotalCartValue(),
          // amount: 1,
          address,
          products: cart.map(item => `${item.name} (x${item.quantity}) - ₹${item.price * item.quantity}`).join(', '),
        };

        await sendConfirmationEmail(orderDetails);

        // Show success message modal
        setShowModal(true);

        // Redirect to homepage after 3 seconds
        setTimeout(() => {
          setShowModal(false);
          navigate('/'); // Updated to navigate to the homepage
        }, 3000); // Wait for 3 seconds
      },
      prefill: {
        name: address.fullName,
        email: address.email,
      },
      theme: {
        color: '#ff6f61',
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Function to send confirmation email to both the user and the admin
  const sendConfirmationEmail = async (orderDetails) => {
    try {
      const emailData = {
        user_name: orderDetails.address.fullName,
        user_email: orderDetails.address.email,
        admin_email: 'admin@example.com', // Replace with your email
        order_id: orderDetails.order_id,
        payment_id: orderDetails.payment_id,
        total_price: orderDetails.amount,
        products: orderDetails.products,
      };

      // Send email using EmailJS
      await emailjs.send('service_xod90os', 'template_521sken', emailData, '7AsN00SXYkE8hedk4');
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending confirmation email:', error);
    }
  };

  return (
    <div className="checkout">
      <h1>Checkout</h1>
      <div className="checkout__address">
        <h2>Shipping Address</h2>
        <form>
          <label>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={address.fullName}
              onChange={handleInputChange}
              required
            />
            {errors.fullName && <p className="error">{errors.fullName}</p>}
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={address.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </label>
          <label>
            Address Line 1:
            <input
              type="text"
              name="addressLine1"
              value={address.addressLine1}
              onChange={handleInputChange}
              required
            />
            {errors.addressLine1 && <p className="error">{errors.addressLine1}</p>}
          </label>
          <label>
            Address Line 2:
            <input
              type="text"
              name="addressLine2"
              value={address.addressLine2}
              onChange={handleInputChange}
            />
          </label>
          <label>
            City:
            <input
              type="text"
              name="city"
              value={address.city}
              onChange={handleInputChange}
              required
            />
            {errors.city && <p className="error">{errors.city}</p>}
          </label>
          <label>
            Postal Code:
            <input
              type="text"
              name="postalCode"
              value={address.postalCode}
              onChange={handleInputChange}
              required
            />
            {errors.postalCode && <p className="error">{errors.postalCode}</p>}
          </label>
          <label>
            Country:
            <input
              type="text"
              name="country"
              value={address.country}
              onChange={handleInputChange}
              required
            />
            {errors.country && <p className="error">{errors.country}</p>}
          </label>
        </form>
      </div>

      <div className="checkout__summary">
        <h2>Order Summary</h2>
        <ul>
          {cart.map((item) => (
            <li key={item.id}>
              {item.name} x {item.quantity} = ₹{item.price * item.quantity}
            </li>
          ))}
        </ul>
        <h3>Total: ₹{getTotalCartValue()}</h3>
        <button onClick={handlePayment}>Pay now</button>
      </div>

      {/* Modal for Payment Success */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Order Placed Successfully!</h2>
            <p>You will receive a confirmation email shortly with the order details.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;

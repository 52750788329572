// src/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://litluxe-admin-production.up.railway.app/api/graphql', // Your Keystone GraphQL API endpoint
  }),
  cache: new InMemoryCache(),
});

export default client;

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { FaShoppingCart } from 'react-icons/fa';
// import './FloatingCartButton.scss'; // We'll define styles separately

const FloatingCartButton = () => {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  const handleClick = () => {
    navigate('/cart');
  };

  return (
    <div className="floating-cart-button" onClick={handleClick}>
      <FaShoppingCart size={24} />
      {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
    </div>
  );
};

export default FloatingCartButton;

import React, { useState, useEffect } from 'react';
import ProductGrid from '../components/ProductGrid';
import { useQuery, gql } from '@apollo/client';
import Loader from '../components/Loader';

const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      id
      name
      price
      volume
      description
      titleImage {
        publicUrl
      }
      image1 {
        publicUrl
      }
      image2 {
        publicUrl
      }
      accent
      featured
      topRated
      bestSeller
      variants {
        name
        price
        image {
          publicUrl
        }
      }
      relatedProducts {
        name
        price
        titleImage {
          publicUrl
        }
        image1 {
          publicUrl
        }
        image2 {
          publicUrl
        }
      }
    }
  }
`;

const Shop = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filter, setFilter] = useState({ price: '', category: '' });

  // Fetch products using Apollo's useQuery
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  // Always call useEffect but check for data within it
  useEffect(() => {
    if (data && data.products) {
      filterProducts(searchTerm, filter.price, filter.category);
    }
  }, [data, searchTerm, filter]);

  // Filter products based on search term, price, and category
  const filterProducts = (searchTerm, price, category) => {
    let filtered = [...data.products];

    if (searchTerm) {
      filtered = filtered.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (price) {
      if (price === 'low-to-high') {
        filtered = filtered.sort((a, b) => a.price - b.price);
      } else if (price === 'high-to-low') {
        filtered = filtered.sort((a, b) => b.price - a.price);
      }
    }

    if (category) {
      filtered = filtered.filter(product => product.category === category);
    }

    setFilteredProducts(filtered);
  };

  // Update search term
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Update filter options
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  if (loading) return <Loader/>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="shop">
      <h1>Shop All Products</h1>
      <div className="shop__controls">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="shop__search"
        />
        <div className="shop__filters">
          <select name="price" onChange={handleFilterChange} className="shop__filter">
            <option value="">Sort by Price</option>
            <option value="low-to-high">Low to High</option>
            <option value="high-to-low">High to Low</option>
          </select>
          {/* <select name="category" onChange={handleFilterChange} className="shop__filter">
            <option value="">Filter by Category</option>
            <option value="skincare">Skincare</option>
            <option value="makeup">Makeup</option>
            <option value="bodycare">Bodycare</option>
          </select> */}
        </div>
      </div>

      <ProductGrid products={filteredProducts} />
    </div>
  );
};

export default Shop;

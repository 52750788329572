import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import Markdown from 'react-markdown';
import Loader from '../components/Loader';

// GraphQL query to get the product by ID
const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: ID!) {
    product(where: { id: $id }) {
      id
      name
      description
      price
     titleImage {
      publicUrl
    }
    image1 {
      publicUrl
    }
    image2 {
      publicUrl
    }
      variants {
        id
        name
        price
        image
        {
        publicUrl
        }
        volume
      }
      relatedProducts {
        id
        name
        titleImage {
      publicUrl
    }
    image1 {
      publicUrl
    }
    image2 {
      publicUrl
    }
        discountedPrice
      }
    }
  }
`;

const ProductDetail = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const { addToCart } = useContext(CartContext);

  // Fetch the product data from Keystone API using Apollo's useQuery
  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id },
  });

  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(null); // Initially no variant selected
  const [showRelatedProducts, setShowRelatedProducts] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // Assume product.images is an array of image URLs
 // This will run once the data is available
 useEffect(() => {
  if (data && data.product) {
    // Set the default image (either from images or titleImage)
    if (data.product.images && data.product.images.length > 0) {
      setSelectedImage(data.product.images[0].publicUrl); // Assuming images is an array
    } else if (data.product.titleImage) {
      setSelectedImage(data.product.titleImage.publicUrl);
    }

    // Set the default variant (if available)
    if (data.product.variants && data.product.variants.length > 0 && !selectedVariant) {
      setSelectedVariant(data.product.variants[0]);
    }
  }
}, [data, selectedVariant]);
  // Handle loading and error states
  if (loading) return <Loader/>;
  if (error) return <p>Error: {error.message}</p>;

  // Product data fetched from GraphQL
  const product = data.product;
  
 

  // Handle variant change
  const handleVariantChange = (variantId) => {
    const variant = product.variants.find(v => v.id === variantId);
    setSelectedVariant(variant);
  };

  // Handle adding product or variant to cart
  const handleAddToCart = () => {
    addToCart(selectedVariant || product, quantity);
    if (product.relatedProducts && product.relatedProducts.length > 0) {
      setShowRelatedProducts(true);
    }
  };

  // Handle adding related products to the cart
  const handleAddRelatedProduct = (relatedProduct) => {
    addToCart(relatedProduct, 1, relatedProduct.discountedPrice); // Default to 1 quantity for related products
    setShowRelatedProducts(false);
  };

  return (
    <div className="product-detail">
      <div className="product-detail__container">
        {/* <div className="product-detail__image">
          <img src={(selectedVariant?.title || product.titleImage.publicUrl)} alt={selectedVariant?.name || product.name} />
        </div> */}
        <div className="product-detail__images">
          <div className="product-detail__main-image">
            <img src={selectedImage} alt={product.name} className="zoomable" />
          </div>
          <div className="product-detail__thumbnails">
            {/* Display the title image as the first thumbnail */}
            {product.titleImage && (
              <img
                src={product.titleImage.publicUrl}
                alt="Title Image"
                onClick={() => setSelectedImage(product.titleImage.publicUrl)}
                className={`product-detail__thumbnail ${selectedImage === product.titleImage.publicUrl ? 'active' : ''}`}
              />
            )}

            {/* Display the first additional image */}
            {product.image1 && (
              <img
                src={product.image1.publicUrl}
                alt="Image 1"
                onClick={() => setSelectedImage(product.image1.publicUrl)}
                className={`product-detail__thumbnail ${selectedImage === product.image1.publicUrl ? 'active' : ''}`}
              />
            )}

            {/* Display the second additional image */}
            {product.image2 && (
              <img
                src={product.image2.publicUrl}
                alt="Image 2"
                onClick={() => setSelectedImage(product.image2.publicUrl)}
                className={`product-detail__thumbnail ${selectedImage === product.image2.publicUrl ? 'active' : ''}`}
              />
            )}
          </div>
        </div>

        <div className="product-detail__info">
          <h1 className="product-detail__name">{product.name}</h1>
          <div className='product-detail__description'>

          <ReactMarkdown >{selectedVariant?.description || product.description}</ReactMarkdown>  

          </div>
          
          <p className="product-detail__price">₹{selectedVariant?.price || product.price}</p>

          {product.variants.length > 0 && (
            <div className="product-detail__variants">
              <label>Select Variant:</label>
              <select onChange={(e) => handleVariantChange(e.target.value)} value={selectedVariant?.id}>
                {product.variants.map(variant => (
                  <option key={variant.id} value={variant.id}>
                    {variant.name} - ₹{variant.price}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="product-detail__quantity">
            <label>Quantity:</label>
            <input
              type="number"
              value={quantity}
              min="1"
              onChange={(e) => setQuantity(parseInt(e.target.value))}
            />
          </div>

          <button className="product-detail__add-to-cart" onClick={handleAddToCart}>
            Add to Cart
          </button>

          {showRelatedProducts && (
        <div className="modal-overlay">
          <div className="related-products-modal">
            <h2>Special Offer!</h2>
            <p>Buy these products together at a discount:</p>
            <ul>
              {product.relatedProducts.map((relatedProduct) => (
                <li key={relatedProduct.id}>
                  <img src={relatedProduct.titleImage.publicUrl} alt={relatedProduct.name} />
                  <p>{relatedProduct.name}</p>
                  <p>Discounted Price: ₹{relatedProduct.discountedPrice}</p>
                  <button onClick={() => handleAddRelatedProduct(relatedProduct)}>
                    Add {relatedProduct.name} to Cart
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={() => setShowRelatedProducts(false)}>No Thanks</button>
          </div>
        </div>
      )}{showRelatedProducts && (
        <div className="modal-overlay">
          <div className="related-products-modal">
            <h2>Special Offer!</h2>
            <p>Buy these products together at a discount:</p>
            <ul>
              {product.relatedProducts.map((relatedProduct) => (
                <li key={relatedProduct.id}>
                  <img src={relatedProduct.titleImage.publicUrl} alt={relatedProduct.name} />
                  <p>{relatedProduct.name}</p>
                  <p>Discounted Price: ₹{relatedProduct.discountedPrice}</p>
                  <button onClick={() => handleAddRelatedProduct(relatedProduct)}>
                    Add {relatedProduct.name} to Cart
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={() => setShowRelatedProducts(false)}>No Thanks</button>
          </div>
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;

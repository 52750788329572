// import React from 'react'
// import Slider from "react-slick";
import ProductCard from './ProductCard';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import Toast from './Toast';
import ReactMarkdown from 'react-markdown';


function BestSeller({ products }) {
  const { addToCart } = React.useContext(CartContext);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);

  const handleAddToCart = (e) => {
    e.stopPropagation(); // Prevent any parent click events from triggering
    addToCart(product, 1);
    setShowToast(true); // Show the toast notification
  };

  const handleBuyNow = (e) => {
    e.stopPropagation(); // Prevent any parent click events from triggering
    addToCart(product, 1);
    navigate('/checkout');
  };

  const closeToast = () => {
    setShowToast(false);
  };
  const product = products[0]
  const getFirstLine = (description) => {
    return description.split('.')[0] + '.';
  };
  return (

    <div className="bestseller-card" style={{background:product.accent}}>
      {/* <img src={`/assets/${product.titleImage}`} alt={product.name} /> */}
      <div className="bestseller-image">
        <div className="bestseller-content">
          <h1>Our Bestseller</h1>
          <ReactMarkdown>{getFirstLine(product.description)}</ReactMarkdown>
          <div className="product-actions">
            <p className="price">₹{product.price}</p>
            <button onClick={handleAddToCart} className="atc">Add to Cart</button>
            <button onClick={handleBuyNow}>Buy Now</button>
          </div>
        </div>
        <Link to={`/product/${product.id}`} className="product-link">

          <img src={product.titleImage.publicUrl} alt={product.name} />

        </Link>
      </div>

      {/* </Link> */}


      {/* Toast notification */}
      <Toast
        message="Item successfully added to cart!"
        show={showToast}
        onClose={closeToast}
      />
    </div>

  );
}

export default BestSeller



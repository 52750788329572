import React from 'react';
import ProductList from '../components/ProductList';
import products from '../data/products.json';
import BestSeller from '../components/BestSeller';
import Testimonial from '../components/Testimonials';
import { FaStar, FaLeaf, FaHeart } from 'react-icons/fa';
import { useQuery, gql } from '@apollo/client';
import {Link} from 'react-router-dom';
import Loader from '../components/Loader';
const Home = () => {
  const GET_PRODUCTS = gql`
  query GetProducts {
  products {
    id
    name
    price
    volume
    description
    titleImage {
      publicUrl  # Corrected the case to publicUrl
    }
    image1 {
      publicUrl  # Corrected the case to publicUrl
    }
    image2 {
      publicUrl  # Corrected the case to publicUrl
    }
    accent
    featured
    topRated
    bestSeller
    variants {
      name
      price
      image {
        publicUrl  # Make sure the variant's image is also a Cloudinary image
      }
    }
    relatedProducts {
      name
      price
      titleImage {
        publicUrl  # Corrected the case to publicUrl
      }
      image1 {
        publicUrl  # Corrected the case to publicUrl
      }
      image2 {
        publicUrl  # Corrected the case to publicUrl
      }
    }
  }
}
`;

  // Fetch the products using Apollo's useQuery hook
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  // Handle loading and error states
  if (loading) return <Loader/>;
  if (error) return <p>Error: {error.message}</p>;

  // Add checks to ensure data is defined before accessing properties
  const featuredProducts = data?.products?.filter(product => product?.featured) || [];
  const topRatedProducts = data?.products?.filter(product => product?.topRated) || [];
  const bestSellers = data?.products?.filter(product => product?.bestSeller) || [];

  return (
    <div>
      {/* <div className="banner">
        <h2>Latest Offer: Get 20% off on all products!</h2>
      </div> */}
      {/* <section className="hero">
        <video className="hero-video" autoPlay loop muted playsInline>
          <source src="/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-wrapper">
          <div className="hero-text">
            Welcome to Lit Luxe, where beauty meets brilliance!
            Our quirky, vibrant collection of personal care items is
            designed to bring out your inner glow.
            <br /><br />
            Whether you’re a makeup maven or skincare savant,
            our eco-friendly products are crafted for every skin
            type, ensuring that everyone can shine bright.
            <br /><br />
            At Lit Luxe, we’re not just about looking good—we’re
            here to add a little sparkle to your life.
            <br /><br />
            Dive into our world of fun, fabulous, and feel-good
            beauty!
          </div>
          <img src="assets/logo.png" alt="Hero Image" />
        </div>
      </section> */}

      <section className="hero">
        <video className="hero-video" autoPlay loop muted playsInline>
          <source src="/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay"></div>
        <div className="hero-wrapper">
          <div className="hero-content">
            <div className="hero-text">
              <h1 className="animated-text">Welcome to Lit Luxe, where beauty meets brilliance!</h1>
              <p>Our quirky, vibrant collection of personal care items is designed to bring out your inner glow.</p>
              <p>Whether you’re a makeup maven or skincare savant, our eco-friendly products are crafted for every skin type, ensuring that everyone can shine bright.</p>
              <p>At Lit Luxe, we’re not just about looking good—we’re here to add a little sparkle to your life.</p>
              <p>Dive into our world of fun, fabulous, and feel-good beauty!</p>
              {/* <button className="cta-button">Shop Now</button> */}
              <Link to="/shop" className="cta-button">
              Shop Now
              </Link>
            </div>
            <img src="assets/logo.png" alt="Hero Image" className="animated-logo" />
          </div>
        </div>
      </section>


      <section className="lit-luxe-section">
        {/* Infinite Banner */}
        <div className="lit-luxe-banner">
          <span>"Lit Luxe: Quirky, Bright, Uniquely You – Premium skincare for every skin type!"</span>
        </div>

        {/* Main Content */}
        <div className="lit-luxe-content">
          <p className="lit-luxe-text">
            <strong>Welcome to Lit Luxe: Where Every Moment Sparkles!</strong>
            <br /><br />
            At Lit Luxe, we're all about bringing a splash of color and a dash of fun to your everyday routine. Our personal care products are designed to celebrate every unique shade of you—because life’s too short for boring, one-size-fits-all beauty!
            <br /><br />
            <strong>Why Lit Luxe?</strong>
            <br /><br />
            <div className="lit-luxe-features">
              <div className="lit-luxe-feature-item">
                <FaStar className="lit-luxe-icon" />
                <strong>Quirky & Bright:</strong>
                <p>
                  From vibrant lotions to radiant skincare, our products are as unique as you are. We believe that your personal care routine should be a burst of joy every single day!
                </p>
              </div>
              <div className="lit-luxe-feature-item">
                <FaLeaf className="lit-luxe-icon" />
                <strong>Eco-Friendly & Fabulous:</strong>
                <p>
                  Our commitment to the planet is as strong as our love for creativity. That's why our products are eco-friendly, cruelty-free, and packed with natural goodness. Feel good, look good, and do good!
                </p>
              </div>
              <div className="lit-luxe-feature-item">
                <FaHeart className="lit-luxe-icon" />
                <strong>For Every Skin Type:</strong>
                <p>
                  We embrace diversity in all its beautiful forms. Whether your skin is dry, oily, sensitive, or somewhere in between, we've crafted our products to bring out the best in you—because you deserve to shine!
                </p>
              </div>
            </div>
            <br /><br />
            <strong>Join the Lit Luxe Family</strong>
            <br /><br />
            Ready to add a little sparkle to your life? Whether you're looking to brighten up your daily routine or find the perfect gift, Lit Luxe has got you covered. Explore our collection today and discover what it means to be <em>Quirky, Bright, Uniquely You!</em>
          </p>
        </div>

      </section>
      <section className="featured-section">
        {/* <h2>Featured Products</h2> */}
        <ProductList products={featuredProducts} />
      </section>
      {/* <section className="about__us">
        <div className="about__us__wrapper">
          <h2>About Us</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus corrupti nam tempora distinctio, alias deleniti quasi fugiat quibusdam, facere eveniet placeat magnam sequi, voluptate adipisci iusto dolore necessitatibus. Animi sint temporibus obcaecati fuga exercitationem vitae cum id officia culpa nihil reiciendis porro dolore, itaque delectus modi perspiciatis quia accusamus adipisci veritatis totam quidem cupiditate, reprehenderit atque. Consequatur in consectetur, dolore assumenda alias atque neque nesciunt quas dolorum nobis illo placeat. Amet minus itaque explicabo, quis quibusdam numquam modi inventore laborum vitae, rem aut commodi ex sed ab fuga saepe unde iure laboriosam quasi atque et qui excepturi eos! Exercitationem, dolorum necessitatibus veritatis placeat consequatur animi! Atque laudantium ullam, fuga quod accusantium voluptates quidem maxime minima consectetur voluptatem, sed, nemo consequatur rerum ea. Odit enim porro itaque, aperiam laboriosam veritatis dolores dolorum sequi molestiae deserunt. Itaque unde eveniet maiores illum, quisquam libero provident assumenda exercitationem at odit, debitis eaque laboriosam natus!</p>
        </div>
      </section> */}
      {/* <section className='photo-section'>
        <img src="assets/section2.png" alt="" />
      </section> */}
      <section className="text2">
        <div className="left">
          <div className="left__images">
            <img src="assets/feature1.png" alt="" />
            <img src="assets/feature2.png" alt="" />
            <img src="assets/feature3.png" alt="" />
            <img src="assets/feature4.png" alt="" />
            <img src="assets/feature5.png" alt="" />
            <img src="assets/feature6.png" alt="" />
          </div>
          <h2>*SUITABLE FOR ALL SKIN TYPES*</h2>
        </div>
        <div className="right">
          <img src="assets/feature.png" alt="" />
        </div>
      </section>
      {bestSellers.length > 0 && (
        <BestSeller products={bestSellers} />

      )}
      <Testimonial />
    </div>
  );
};

export default Home;
